import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Spinner,
} from "react-bootstrap";
import "./user.css";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import * as XLSX from "xlsx";
import "react-data-table-component-extensions/dist/index.css";
import "./user.scss";
import UpdateUser from "./UpdateUser";
import AddUser from "./AddUser";
import user1 from "../../../assets/img/users/place_holder.png";
import CustomerService from "../../../Redux/services/CustomerService";
import { format } from "date-fns";

// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { retrieveuser } from "../../../Redux/Slices/UserSlice";
// import { Avatar } from "@mui/material";
// import { Form } from "react-bootstrap";
// import Select from "react-select";
// import user from "../../../assets/img/doctor_noimage.png";
// import Lottie from "lottie-react";
// import animation from "../../../assets/food/95494-double-loader.json";
// import Error404 from "../../Custompages/Error1-404/Error-404";
// import BaseURL from "../../../data/Api/baseUrl";

function ActiveUsers() {
  // const dispatch = useDispatch();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageLimit, setPerPage] = useState(10);
  // const [status, setStatus] = useState("");
  const [totalRows, settotalrows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [userviews, setUserviews] = useState(false);
  const [add_user, setadd_user] = useState(false);
  const [userUpdate, setuserUpdate] = useState("");
  const [deleteCustomer, setDeleteCustomer] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState("updated_at");
  const [sortDir, setSortDir] = useState("desc");
  const [loadingExcel, setLoadingExcel] = useState(false);

  const [startDate, setStartDate] = useState(""); // Holds the Date object
  const [startDate1, setStartDate1] = useState(""); // Holds the formatted date string
  const [endDate, setEndDate] = useState("");
  const [endDate1, setEndDate1] = useState("");

  const handleStartDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd"); // Format the date
      setStartDate(date); // Set the Date object
      setStartDate1(formattedDate); // Set the formatted string
    } else {
      setStartDate(null);
      setStartDate1("");
    }
  };
  const handleEndDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd"); // Format the date
      setEndDate(date); // Set the Date object
      setEndDate1(formattedDate); // Set the formatted string
    } else {
      setEndDate(null);
      setEndDate1("");
    }
  };

  const handleDeleteCustomer = () => {};

  const showUser = (item) => {
    setuserUpdate(item);
    setUserviews(true);
  };

  const Adduser = () => {
    setadd_user(true);
  };

  let SystemAdmin = JSON.parse(localStorage.getItem("user"));

  const [loading, setloading] = useState(true);

  const handlePageChange = () => {
    if (SystemAdmin?.user_type === "SHOP_OWNER") {
      CustomerService.getCustomerShop({
        currentPage,
        pageLimit,
        shop_id: SystemAdmin?.shop_id,
      })
        .then((res) => {
          setData(res.users.data);
          settotalrows(res.users.total);
          setloading(false);
        })
        .catch((err) => console.log(err));
    } else {
      CustomerService.getActiveCustomerPaginated(
        currentPage,
        pageLimit,
        searchText,
        sort,
        startDate1,
        endDate1,
        sortDir
      )
        .then((res) => {
          setData(res.users.data);
          settotalrows(res.users.total);
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const exportToExcel = async () => {
    setLoadingExcel(true);
    try {
      let allData = [];
      const response = await CustomerService.getActiveCustomerNotPaginated(
        startDate1,
        endDate1
      );
      console.log("response of Export Excel", response.data.users);
      const newData = response.data.users;

      if (newData.length > 0) {
        allData = [...allData, ...newData];
      }

      const extractedData = allData.map((row) => ({
        "Customer ID": row.id,
        Name: row
          ? row?.name || row?.middle_name || row?.last_name
            ? `${row?.name ?? ""}${" "}${row?.middle_name ?? ""}${" "}${
                row?.last_name ?? ""
              }`
            : "N/A"
          : "N/A",
        "Phone Number":
          `${row.phone?.country_code ?? "-"}${
            row.phone?.phone_number ?? "-"
          }` ?? "-",
        Email: row?.email?.email || "-",
        "Activated On": moment(row?.updated_at).format("LL"),
      }));
      const ws = XLSX.utils.json_to_sheet(extractedData, {
        header: Object.keys(extractedData[0]),
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "All Customers.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExcel(false);
    }
  };

  useEffect(() => {
    handlePageChange();
  }, [pageLimit, currentPage, searchText, sort, sortDir, startDate, endDate]);

  const handleRowClicked = (row) => {
    data.map((item) => {
      if (row.id !== item.id) {
        return item;
      }
      navigate(`/customerview/${item.id}`);
    });
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => [row?.name],
      sortable: true,

      cell: (row) => (
        <div className="user_pro">
          {!row?.profile_picture ? (
            <img
              src={user1}
              width="50px"
              height="50px"
              alt="profile"
              className="img rounded-circle "
            />
          ) : (
            <img
              src={row?.profile_picture}
              alt="profile"
              height="50px"
              width="50px"
              className="img rounded-circle "
            />
          )}
          <span> {row.name || "N/A"} </span>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => [row?.phone],
      cell: (row) => (
        <div className="mb-2 mt-2">
          <div>
            {row.phone?.country_code}
            {row.phone?.phone_number}
          </div>
          <br />
          <div>{row?.email?.email || "-"}</div>
        </div>
      ),
    },

    {
      name: "Activated On",
      cell: (row) => <div>{moment(row?.updated_at).format("LL")}</div>,
      sortable: true,
    },
    {
      name: "ACTIONS",
      selector: (row) => [row.ACTIONS],

      cell: (row) => (
        <div className="button-list">
          {SystemAdmin.user_type === "SYS_ADMIN" ? (
            <OverlayTrigger
              placement={row.Placement}
              overlay={<Tooltip> Edit</Tooltip>}
            >
              <i onClick={() => showUser(row)} className="ti ti-pencil btn"></i>
            </OverlayTrigger>
          ) : null}

          <OverlayTrigger overlay={<Tooltip> View</Tooltip>}>
            <i
              onClick={() => navigate(`/customerview/${row.id}`)}
              className="ti ti-eye btn"
            ></i>
          </OverlayTrigger>

          {/* <OverlayTrigger
            placement={row.Placement}
            overlay={<Tooltip> Delete</Tooltip>}
          >
            <i
              className="ti ti-trash"
              onClick={() => {
                setDeleteCustomer(row);
              }}
            ></i>
          </OverlayTrigger> */}
        </div>
      ),
    },
  ];

  var click = (id) => {
    let i = data.filter((e, index) => {
      return e.ID !== id;
    });
    setData(i);
  };
  const tableData = {
    columns,
    data,
  };
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("csv_file", file);
    try {
      CustomerService.importUserCsv(formData)
        .then((res) => {
          if (res?.status === "succes") {
            toast(res.message);
            fileInputRef.current.value = "";
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const handleSort = (column, sortDirection) => {
    if (column.name === "Activated On") {
      setSort("updated_at");
      setSortDir(sortDirection);
    } else {
      setSort(column.name);
      setSortDir(sortDirection);
    }
  };
  if (loading) {
    return (
      <div className="animie_shops">
        <div
          className="spinner-grow spinner-grow-lg"
          style={{ width: "5rem", height: "5rem", color: "#78B833" }}
        ></div>
      </div>
    );
  } else {
    return (
      <Fragment>
        {/* <!-- Page Header --> */}
        <div className="page-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Customer</h2>
            <Breadcrumb>
              <Breadcrumb.Item href="#">All</Breadcrumb.Item>
              <Breadcrumb.Item active>Customer</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="d-flex">
            <div className="justify-content-center"></div>
          </div>
        </div>
        {/* <!-- End Page Header --> */}
        {/* <!-- Row --> */}
        <Row className="row-sm">
          <Col md={12} lg={12}>
            <Card className=" custom-card">
              <Card.Header className=" border-bottom-0">
                <div className="addCategory ">
                  <label className="main-content-label my-auto pt-2">
                    Active Customers
                  </label>

                  <div className="d-flex filters">
                    <DatePicker
                      selected={startDate} // Pass the Date object to DatePicker
                      onChange={handleStartDateChange} // Handle date selection
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="From"
                      className="date-picker"
                    />
                    {/* Display formatted date */}
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="To"
                      className="date-picker"
                    />
                    <Button
                      variant="outline-primary"
                      className="rounded-2 import-btn"
                      onClick={openFileInput}
                    >
                      Import
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      accept=".csv"
                    />
                    <Button
                      variant="outline-primary"
                      className="rounded-2"
                      onClick={exportToExcel}
                      disabled={loadingExcel}
                    >
                      {loadingExcel ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Export Customer Details</span>
                      )}
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div>
                  <input
                    type="text"
                    placeholder="Filter Table..."
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{
                      marginBottom: "10px",
                      backgroundColor: "transparent",
                      border: "1px solid #e8e8f7",
                      borderRadius: "7px",
                      outline: "none",
                      padding: "0.375rem 0.75rem",
                      height: "35px",
                      fontSize: "14px",
                      float: "right",
                    }}
                  />
                  <DataTableExtensions {...tableData} filter={false}>
                    <DataTable
                      columns={columns}
                      defaultSortAsc={false}
                      pagination={true}
                      paginationServer={true}
                      paginator
                      rows={1}
                      paginationRowsPerPageOptions={[10, 30, 50, 100]}
                      paginationTotalRows={totalRows}
                      onRowClicked={handleRowClicked}
                      onChangeRowsPerPage={(p) => {
                        setPerPage(p);
                        setCurrentPage(1);
                      }}
                      onChangePage={(p) => setCurrentPage(p)}
                      onSort={handleSort}
                      sortServer
                    />
                  </DataTableExtensions>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <!-- End Row --> */}

        <UpdateUser
          data={userUpdate}
          open={userviews}
          onUpdate={() => {
            handlePageChange();
          }}
          onClose={() => setUserviews(false)}
        />

        <AddUser open={add_user} onClose={() => setadd_user(false)} />

        {/* Delete Customer */}

        <Modal show={deleteCustomer} size="large">
          <Modal.Header
            closeButton
            onClick={() => {
              setDeleteCustomer(false);
            }}
          >
            <h6>Delete Customer </h6>
          </Modal.Header>
          <Modal.Body>
            Do you want to delete <strong> {deleteCustomer?.name}</strong>{" "}
            Customer?
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                handleDeleteCustomer();
              }}
              className="text-center"
            >
              Continue
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setDeleteCustomer(undefined);
              }}
              className="text-center"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

ActiveUsers.defaultProps = {};

export default ActiveUsers;
