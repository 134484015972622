import BaseURL from "../../data/Api/baseUrl";

const getAll = () => {
  return BaseURL.get(`role/get`);
};

// const get = (id) => {
//     return BaseURL.get(`doctor/${id}`);
// };

// const permission = () => {
//     return BaseURL.get(`permission/admin/`);
// };

const create = (name, permissions) => {
  return BaseURL.post(`role/create`, name, permissions);
};

// const update = (_id, data) => {
//     return BaseURL.putForm(`doctor/update/${_id}`, data);
// };

// const remove = (_id) => {
//     return BaseURL.delete(`doctor/${_id}`);
// };

const RoleService = {
  getAll,
  // get,
  create,
  // update,
  // remove,
  // permission
};

export default RoleService;
