/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import Select from "react-select";
import customerUpdate from "../../../Redux/services/CustomerService";
import { toast, ToastContainer } from "react-toastify";

const UpdateUser = ({ open, onClose, onUpdate, data }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [date_of_birth, setDate_of_birth] = useState("");
  const [email, setEmail] = useState("");
  const [profile_pic, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(data?.phone?.country_code);

  console.log("countrycode", data?.phone?.country_code);

  const notify = () => toast("Customer Updated Successfully!");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, onClose]);

  const handleFileInputChange = (event) => {
    setImage(event.target.files[0]);
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setName(data?.name);
    setCity(data?.city);
    setGender(data?.gender);
    setDate_of_birth(data?.date_of_birth);
    setEmail(data?.email?.email);
    setImage(data?.profile_picture);
    setId(data?.id);
    setPhoneNumber(data?.phone?.phone_number);
    setCountryCode(data?.phone?.country_code);
  }, [data]);

  const handlePhoneChange = (isValid, phoneNumber, countryCode) => {
    setPhoneNumber(phoneNumber);
    setCountryCode(countryCode);
  };

  const option1 = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "unknown",
      label: "Unknown",
    },
  ];

  const handleUserUpdate = (e) => {
    e.preventDefault();
    setLoading(true);

    customerUpdate
      .customerUpdate({
        id,
        name,
        city,
        gender,
        phone_number: phoneNumber,
        country_code: countryCode?.dialCode,
        dob: date_of_birth,
        email,
        profile_picture: profile_pic,
      })
      .then((res) => {
        onUpdate();
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        onClose();
        notify();
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <div>
        <Modal show={open} size="lg" backdrop="static">
          <Modal.Header closeButton onClick={onClose}>
            <Modal.Title>Edit Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Modal.Title>All Details </Modal.Title> */}
            <div>
              <Form.Group className=" mb-3 updateuser">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    defaultValue={data?.name}
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
                <Form.Label className="pt-3">City</Form.Label>
                <InputGroup>
                  <Form.Control
                    defaultValue={data?.city}
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </InputGroup>

                <Form.Label className="pt-3">Gender</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  options={option1}
                  defaultValue={{ label: data?.gender, value: data?.gender }}
                  name="gender"
                  onChange={(selectedOption) => {
                    setGender(selectedOption.value);
                    console.log(selectedOption.value);
                  }}
                />

                <Form.Label className="pt-3">Date of Birth</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    defaultValue={data?.date_of_birth}
                    placeholder="date_of_birth"
                    name="date_of_birth"
                    onChange={(e) => setDate_of_birth(e.target.value)}
                  />
                </InputGroup>
                <Form.Label className="pt-3">Email</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="email"
                    defaultValue={data?.email?.email}
                    placeholder="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <Form.Label className="pt-3">Phone Number</Form.Label>
                <InputGroup className="telephone-input">
                  <IntlTelInput
                    style={{ width: "100%" }}
                    preferredCountries={countryCode}
                    defaultCountry={data?.phone?.country_code}
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    name="phone_number"
                    placeholder="Enter Your Number"
                    input
                    defaultValue={data?.phone?.phone_number}
                    type="number"
                    value={phoneNumber}
                    onPhoneNumberChange={handlePhoneChange}
                  />
                </InputGroup>
                <Form.Label className="pt-3">Profile Pic</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="file"
                    placeholder="profile_pic"
                    name="profile_pic"
                    onChange={handleFileInputChange}
                  />
                </InputGroup>
                {data?.profile_picture ? (
                  <img
                    src={data?.profile_picture}
                    width={"100px"}
                    alt="image"
                  />
                ) : null}
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleUserUpdate}
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Update</span>
              )}
            </Button>
            <Button variant="danger" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <ToastContainer
          limit={1}
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Fragment>
  );
};

export default UpdateUser;
