import BaseURL from "../../data/Api/baseUrl";

const getCustomerPaginated = async (
  currentPage,
  pageLimit,
  search = "",
  sort,
  startDate,
  endDate,
  sortDir
) => {
  try {
    const response = await BaseURL.get(
      `admin/get_users?page=${currentPage}&limit=${pageLimit}&search=${search}&sort=${sort}&sort_dir=${sortDir}&from_date=${startDate}&to_date=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while getting customer paginated", error);
    throw error;
  }
};
const getActiveCustomerPaginated = async (
  currentPage,
  pageLimit,
  search = "",
  sort,
  startDate1,
  endDate,
  sortDir
) => {
  try {
    const response = await BaseURL.get(
      `admin/get_active_users?page=${currentPage}&limit=${pageLimit}&search=${search}&sort=${sort}&sort_dir=${sortDir}&from_date=${startDate1}&to_date=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while getting customer paginated", error);
    throw error;
  }
};

const getCustomerNotPaginated = () => {
  return BaseURL.get("/admin/non_paginated/get_users");
};

const getActiveCustomerNotPaginated = async (startDate1, endDate1) => {
  return await BaseURL.get(
    `/admin/non_paginated/get_active_users?from_date=${startDate1}&to_date=${endDate1}`
  );
};

const getCustomerShop = async ({ currentPage, pageLimit, shop_id }) => {
  try {
    const response = await BaseURL.get(
      `admin/get_shop_customer?page=${currentPage}&limit=${pageLimit}&shop_id=${shop_id}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while getting customer paginated", error);
    throw error;
  }
};

const customerUpdate = async ({
  id,
  name,
  city,
  gender,
  phone_number,
  country_code,
  dob,
  email,
  profile_picture,
}) => {
  try {
    const response = await BaseURL.postForm(`admin/edit_user/${id}`, {
      name,
      city,
      gender,
      phone_number,
      country_code,
      dob,
      email,
      profile_picture,
    });
    return response.data;
  } catch (error) {
    console.log("Error while updating customer", error);
    throw error;
  }
};
const importUserCsv = async (data) => {
  try {
    const response = await BaseURL.postForm(
      `admin/import_users_from_csv`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while getting customer paginated", error);
    throw error;
  }
};

const CustomerService = {
  getCustomerPaginated,
  getActiveCustomerPaginated,
  customerUpdate,
  getCustomerShop,
  importUserCsv,
  getCustomerNotPaginated,
  getActiveCustomerNotPaginated,
};

export default CustomerService;
