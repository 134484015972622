/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { createuser } from "../../../Redux/Slices/UserSlice";
import RoleService from "../../../Redux/services/RoleService";
import { toast, ToastContainer } from "react-toastify";

const AddUser = ({ open, onClose, data }) => {
  console.log("update_data", data);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [preferred_lang, setPreferred_lang] = useState("");
  const [wallet, setWallet] = useState("");
  const [blood_group, setBlood_group] = useState("");
  const [gender, setGender] = useState("");
  const [relationship, setRelationship] = useState("");
  const [date_of_birth, setDate_of_birth] = useState("");
  const [email, setEmail] = useState("");
  const [profile_pic, setImage] = useState(null);
  const [roles_name, setRoles_name] = useState("");

  const handleFileInputChange = (event) => {
    setImage(event.target.files[0]);
  };
  const [national_id, setNational_id] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [password, setPassword] = useState("");

  const handlePhoneChange = (isValid, phoneNumber, countryCode) => {
    setPhoneNumber(phoneNumber);
    setCountryCode(countryCode);
  };

  const [role, setRole] = useState([]);

  const RoleServices = async () => {

  };

  useEffect(() => {
    RoleServices();
  }, []);

  const option1 = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "unknown",
      label: "Unknown",
    },
  ];

  const notify = () => toast("User Added Succesfully!");

  const handleUserUpdate = (e) => {
    e.preventDefault();

    dispatch(
      createuser({
        name,
        preferred_lang,
        wallet,
        blood_group,
        gender,
        phone_number: phoneNumber,
        dialCode: countryCode,
        relationship,
        date_of_birth,
        role: roles_name,
        email,
        national_id,
        password,
        file: profile_pic,
      })
    );
    notify();
    onClose();
  };

  return (
    <div>
      <Modal show={open} size="lg" backdrop="static">
        <Modal.Header closeButton onClick={onClose}>
          <Modal.Title className="add_user">Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Modal.Title>All Details </Modal.Title> */}
          <div>
            <Form.Group className=" mb-3 updateuser">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Preferred Lang</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="preferred_lang"
                  name="preferred_lang"
                  onChange={(e) => setPreferred_lang(e.target.value)}
                />
              </InputGroup>
              <Form.Label>wallet</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="wallet"
                  name="wallet"
                  onChange={(e) => setWallet(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Blood Group</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="blood_group"
                  name="blood_group"
                  onChange={(e) => setBlood_group(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Role</Form.Label>
              {/* <Select
              closeMenuOnSelect={true}
              options={roles}
              name="role"
              onChange={(selectedOption) => {
                setGender(selectedOption.value);
                console.log(selectedOption.value);
              }}
            /> */}
              <select
                className="form-control"
                onChange={(e) => setRoles_name(e.target.value)}
                name="role"
              >
                {role.map((item) => {
                  return (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <Form.Label>Gender</Form.Label>
              <Select
                closeMenuOnSelect={true}
                options={option1}
                name="gender"
                onChange={(selectedOption) => {
                  setGender(selectedOption.value);
                  console.log(selectedOption.value);
                }}
              />
              <Form.Label>National Id</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="national_id"
                  name="national_id"
                  onChange={(e) => setNational_id(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Relationship</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="relationship"
                  name="relationship"
                  onChange={(e) => setRelationship(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Date of Birth</Form.Label>
              <InputGroup>
                <Form.Control
                  type="date"
                  placeholder="date_of_birth"
                  name="date_of_birth"
                  onChange={(e) => setDate_of_birth(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  placeholder="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
              <Form.Label>Phone Number</Form.Label>
              <InputGroup className="telephone-input">
                <IntlTelInput
                  style={{ width: "100%" }}
                  preferredCountries={countryCode}
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                  name="phone_number"
                  placeholder="Enter Your Number"
                  input
                  type="number"
                  onPhoneNumberChange={handlePhoneChange}
                />
              </InputGroup>
              <Form.Label>Profile Pic</Form.Label>
              <InputGroup>
                <Form.Control
                  type="file"
                  placeholder="profile_pic"
                  name="profile_pic"
                  onChange={handleFileInputChange}
                />
              </InputGroup>
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="Password"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUserUpdate}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default AddUser;
