import BaseURL from "../../data/Api/baseUrl";

const getAll = ({ status, page, pageLimit, user_type }) => {
  return BaseURL.get(
    `user/getAllUsers?status=${status}&page=${page}&user_type=${user_type}&limit=${pageLimit}`
  );
};

const get = (id) => {
  return BaseURL.get(`user/admin/userInfo/${id}`);
};

const create = ({
  name,
  preferred_lang,
  wallet,
  blood_group,
  gender,
  phone_number,
  dialCode,
  relationship,
  date_of_birth,
  role,
  email,
  national_id,
  password,
  file,
}) => {
  return BaseURL.post(`user/admin/user/createAdminUser`, {
    name,
    preferred_lang,
    wallet,
    blood_group,
    gender,
    phone_number,
    dialCode,
    relationship,
    date_of_birth,
    role,
    email,
    national_id,
    password,
    file,
  });
};

const update = (id, data) => {
  return BaseURL.putForm(`admin/editAdminUser/${id}`, data);
};

const remove = (id) => {
  return BaseURL.delete(`user/${id}`);
};
const updateNameAndNationalId = (userId, data) => {
  return BaseURL.putForm(`user/admin/updateProfile/${userId}`, data);
};

const UserService = {
  getAll,
  get,
  create,
  update,
  remove,
  updateNameAndNationalId,
};

export default UserService;
