import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../services/UserService";

const initialState = { loading: true, page: 1, data: [] };

export const createuser = createAsyncThunk(
  "user/create",
  async ({
    name,
    preferred_lang,
    wallet,
    blood_group,
    gender,
    phone_number,
    dialCode,
    relationship,
    date_of_birth,
    role,
    email,
    national_id,
    password,
    file,
  }) => {
    const res = await UserService.create({
      name,
      preferred_lang,
      wallet,
      blood_group,
      gender,
      phone_number,
      dialCode,
      relationship,
      date_of_birth,
      role,
      email,
      national_id,
      password,
      file,
    });
    return res.data;
  }
);

export const retrieveuser = createAsyncThunk(
  "user/retrieve",
  async ({ status, page, pageLimit, user_type }) => {
    const res = await UserService.getAll({
      status,
      page,
      pageLimit,
      user_type,
    });
    return res.data;
  }
);

export const updateuser = createAsyncThunk(
  "user/update",
  async ({ id, data }) => {
    const res = await UserService.update(id, data);
    return res.data.user;
  }
);

export const deleteuser = createAsyncThunk("user/delete", async ({ id }) => {
  await UserService.remove(id);
  return { id };
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createuser.fulfilled, (state, action) => {
        state.push(action.payload);
      })
      .addCase(retrieveuser.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateuser.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state[index] = action.payload;
        }
      })
      .addCase(deleteuser.fulfilled, (state, action) => {
        return state.filter((user) => user._id !== action.payload._id);
      });
  },
});

export default userSlice.reducer;
